'use client';

import { Flex } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@urql/next';
import { useContext } from 'react';
import { AppLayoutContext, PeerAccountContext, StyledButton } from '~/app/_components';
import { GetAccountWithContactInfo } from '~/app/_graphql';
function copyToClipboard(contactText: string | null | undefined, info: string) {
  if (!contactText) return;
  navigator.clipboard.writeText(contactText);
  notifications.show({
    title: 'Copied',
    message: `${info} copied to clipboard`,
    color: 'green'
  });
}
const isSSR = typeof window === 'undefined';
export function ProfileDesktopContactInfo() {
  const {
    account
  } = useContext(PeerAccountContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const [{
    data
  }] = useQuery({
    query: GetAccountWithContactInfo,
    variables: {
      id: account.id
    },
    pause: isSSR || !account.id
  });
  if (data?.account?.__typename !== 'Account' || !data.account.contactInfo) return null;
  const accountWithInfo = data?.account;
  const handleEmailClick = () => {
    const sendToName = accountWithInfo?.profile?.name;
    const sendToEmail = accountWithInfo?.contactInfo?.email;
    const subject = `Dome Message: Hi ${sendToName}`;

    // Construct the mailto link
    const mailtoLink = `mailto:${sendToEmail}?subject=${encodeURIComponent(subject)}`;

    // Open the email client
    window.location.href = mailtoLink;
  };
  return <Flex data-testid="contact-info-div" direction={mobile ? 'column' : 'row'} gap="xs" justify="center" w={mobile ? undefined : '100%'} data-sentry-element="Flex" data-sentry-component="ProfileDesktopContactInfo" data-sentry-source-file="ProfileDesktopContactInfo.tsx">
      {accountWithInfo.contactInfo?.email ? <StyledButton type="secondary" text="EMAIL" width={mobile ? undefined : '50%'} shape="square" disablePadding
    // textColor={theme.colors.brand[4]}
    onClick={handleEmailClick} testId="email-button" /> : null}
      {accountWithInfo.contactInfo?.phone ? <StyledButton type="secondary" text="PHONE" width={mobile ? undefined : '50%'} disablePadding shape="square"
    // textColor={theme.colors.brand[4]}
    onClick={() => copyToClipboard(accountWithInfo.contactInfo?.phone, 'Phone number')} testId="phone-button" /> : null}
    </Flex>;
}