'use client';

import 'swiper/css';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { Badge, Drawer, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { usePathname } from 'next/navigation';
import type React from 'react';
import { useContext } from 'react';
import { useWindowHeight } from '~/app/_components';
import { AccountProfileCard } from '../AccountProfileCard';
import { AccountsFilterContext } from '../AccountsFilterContext';
import { ProfessionsFilterContext } from '../ProfessionsFilterContext';
import { SearchFilterMobile } from './SearchFilterMobile';
import { closeButton, filterTitle, mobileDrawerBody, mobileProfileAdjustmentIcon, mobileProfileCardWrapper } from './styles.css';
import { TrimmedAccountFragment } from '~/generated/graphql';
export function SearchResultPageOuterMobile({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    freeTextSearch,
    locationSearch
  } = useContext(AccountsFilterContext);
  const {
    professions
  } = useContext(ProfessionsFilterContext);
  const height = useWindowHeight();
  const pn = usePathname();
  const isOnMyContacts = pn.includes('/contacts');
  const [openedFilters, {
    open: openFilters,
    close: closeFilters
  }] = useDisclosure(false);
  function calcBadgeTruthy() {
    if (!professions.length && !freeTextSearch.length && !locationSearch.length) {
      return false;
    }
    return true;
  }
  function calcBadgeNumber() {
    let badgeNumber = professions.length + locationSearch.length;

    // Add 1 if freeTextSearch is not empty
    if (freeTextSearch) {
      badgeNumber += 1;
    }
    return badgeNumber;
  }
  return <>
      <div className={mobileProfileCardWrapper}>
        <div>
          {children}
          {/* <ScrollToTop /> */}
          {!pn.includes('/start') ? <Flex direction="row">
              <AdjustmentsVerticalIcon className={mobileProfileAdjustmentIcon} data-testid="filter-action" height={40} width={40} color="white" radius={100} onClick={() => {
            // scrollToHeader()
            openFilters();
          }} style={{
            position: 'fixed',
            top: `${height - 80}px`,
            right: '20px'
          }} />
              {calcBadgeTruthy() ? <Badge size="md" circle style={{
            position: 'fixed',
            bottom: '60px',
            right: '20px',
            color: 'black',
            zIndex: '100'
          }}>
                  {calcBadgeNumber()}
                </Badge> : null}
            </Flex> : null}
        </div>

        {/* Filter */}
        <Drawer opened={openedFilters} onClose={closeFilters} closeButtonProps={{
        size: 'xl'
      }} padding="0px" title={isOnMyContacts ? 'FILTER CONTACTS' : 'FILTER COMMUNITY'} classNames={{
        body: mobileDrawerBody,
        close: closeButton,
        title: filterTitle
      }} data-sentry-element="Drawer" data-sentry-source-file="MobileResults.tsx">
          <SearchFilterMobile closeFilters={closeFilters} data-sentry-element="SearchFilterMobile" data-sentry-source-file="MobileResults.tsx" />
        </Drawer>
      </div>
    </>;
}
export function SearchResultPageInnerMobile({
  edges
}: {
  edges: {
    node: TrimmedAccountFragment;
    cursor: string;
  }[];
}) {
  return <>
      {edges.map(edge => <AccountProfileCard account={edge.node} key={edge.cursor} />)}
    </>;
}