'use client';

import { BriefcaseIcon, Cog6ToothIcon, FilmIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { Button, Divider, Flex, Text, Title } from '@mantine/core';
import { useMutation, useQuery } from '@urql/next';
import { useSelector } from '@xstate/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import type React from 'react';
import { Suspense, startTransition, useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { ConnectButton } from '~/app/(accounts)/_components';
import { MarkNotificationAsRead } from '~/app/_graphql';
import { FriendshipState, type FullNotificationFragment } from '~/generated/graphql';
import { AppLayoutContext, IdentityContext, PeerAccountProvider } from '../context';
import { AccountNotificationsContext } from './_components';
import { GetPaginatedNotifications } from './_lib';
import { inViewTriggerDiv, messageWrapper } from './styles.css';
import { SafeAvatarImage } from '~/app/_components';
import Link from 'next/link';
import { Route } from 'next';
import { notifications } from '@mantine/notifications';
function renderNotificationIcon(iconUrl: string) {
  // biome-ignore lint/correctness/noUndeclaredVariables: <explanation>
  const iconMap: {
    [key: string]: JSX.Element;
  } = {
    settingsIcon: <Cog6ToothIcon height={55} color="gray" />,
    filmIcon: <FilmIcon height={55} color="gray" />,
    handThumbUp: <HandThumbUpIcon height={55} color="gray" />
  };
  return iconMap[iconUrl] || <BriefcaseIcon height={55} color="gray" />;
}
export function NotificationsList({
  edges
}: {
  edges: {
    node: FullNotificationFragment;
    cursor: string;
  }[];
}) {
  dayjs.extend(relativeTime);
  const [, update] = useMutation(MarkNotificationAsRead);
  async function handleNotificationOnClick() {
    const _t = await update({});
  }
  function ActionButtons({
    notification
  }: {
    notification: FullNotificationFragment;
  }) {
    const {
      mobile
    } = useContext(AppLayoutContext);
    const {
      currentAccount
    } = useContext(IdentityContext);
    const phoneExists = currentAccount?.profile?.name === notification.friendship?.requester?.profile?.name ? notification.addressePhone : notification.requesterPhone;
    const handleEmailClick = (hasAccepcted?: boolean) => {
      const sendToName = currentAccount?.profile?.name === notification.friendship?.requester?.profile?.name ? notification.friendship?.addressee?.profile?.name : notification.friendship?.requester?.profile?.name;
      const sendToEmail = currentAccount?.profile?.name === notification.friendship?.requester?.profile?.name ? notification.addresseEmail : notification.requesterEmail;
      const subject = hasAccepcted ? `Dome Message: Hi ${sendToName}` : 'Dome Connection Request Reply';
      const msg = `${notification.content}`;
      const body = `${sendToName} wrote: \n${msg}\n\n`;
      // const requesterEmail = `${notification.friendship?.requester?.}`

      // Construct the mailto link
      const mailtoLink = hasAccepcted ? `mailto:${sendToEmail}?subject=${encodeURIComponent(subject)}` : msg !== 'null' ? `mailto:${sendToEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}` : `mailto:${sendToEmail}?subject=${encodeURIComponent(subject)}`;

      // Open the email client
      window.location.href = mailtoLink;
    };
    function handlePhoneClick() {
      if (!phoneExists) return;
      navigator.clipboard.writeText(phoneExists);
      notifications.show({
        title: 'Copied',
        message: 'Phone number copied to clipboard',
        color: 'green',
        position: 'bottom-left'
      });
    }
    if (!notification.friendship?.requester) return <></>;
    if (notification.friendship?.state === FriendshipState.Accepted && notification.title?.includes('Connection Invite from')) return <></>;
    if (notification.friendship?.state === FriendshipState.Accepted && !notification.title?.includes('Connection Invite from')) {
      return <PeerAccountProvider account={notification.friendship.requester}>
          <Flex gap={mobile ? '5px' : '5px'} align={mobile ? 'flex-start' : 'center'} direction={mobile ? 'row' : 'row'} w="100%">
            <Button onClick={() => handleEmailClick(true)} mt={mobile ? '0px' : '3px'} style={{
            border: '1px solid black',
            height: '25px',
            backgroundColor: 'black',
            color: 'white',
            fontSize: '12px'
          }}>
              EMAIL
            </Button>
            {phoneExists ? <Button onClick={handlePhoneClick} mt={mobile ? '0px' : '3px'} style={{
            border: '1px solid black',
            height: '25px',
            backgroundColor: 'black',
            color: 'white',
            fontSize: '12px'
          }}>
                PHONE
              </Button> : null}
            {/* <Button
              onClick={() => router.push(`/${notification.titleUrl}` as Route)}
              mt={mobile ? '0px' : '3px'}
              style={{ border: '1px solid black', height: '25px', backgroundColor: 'white', fontSize: '12px' }}
             >
              PROFILE
             </Button> */}
          </Flex>
        </PeerAccountProvider>;
    }
    return <PeerAccountProvider account={notification.friendship.requester} data-sentry-element="PeerAccountProvider" data-sentry-component="ActionButtons" data-sentry-source-file="NotificationsList.tsx">
        <Flex gap={mobile ? '5px' : '6px'} align={mobile ? 'flex-start' : 'center'} direction={mobile ? 'row' : 'row'} w="100%" maw="100%" wrap="wrap" data-sentry-element="Flex" data-sentry-source-file="NotificationsList.tsx">
          <div style={{
          maxWidth: mobile ? undefined : '60%'
        }}>
            <ConnectButton flexDirection="row" disablePadding data-sentry-element="ConnectButton" data-sentry-source-file="NotificationsList.tsx" />
          </div>
          <Button onClick={() => handleEmailClick(false)} mt={mobile ? '6px' : '3px'} style={{
          border: '1px solid black',
          height: '25px',
          backgroundColor: 'white',
          fontSize: '12px'
        }} data-sentry-element="Button" data-sentry-source-file="NotificationsList.tsx">
            REPLY VIA EMAIL
          </Button>
        </Flex>
      </PeerAccountProvider>;
  }
  return <div data-testid="accept-wrapper" data-sentry-component="NotificationsList" data-sentry-source-file="NotificationsList.tsx">
      {edges.map(edge => <div key={edge.cursor}>
          <Flex key={edge.cursor} p="sm" className={messageWrapper} align="flex-start" gap="md" onClick={() => {
        handleNotificationOnClick();
      }} data-testid="notification-title" style={{
        backgroundColor: edge.node.isRead ? undefined : '#f1f3f5'
      }}>
            <Flex direction="column" justify="center" h="100%">
              {edge.node.icon?.startsWith('http') ? <SafeAvatarImage radius="sm" src={edge.node.icon} /> : <div>{renderNotificationIcon(edge.node.icon ?? '')}</div>}
            </Flex>

            <Flex direction="column" justify="center" h="100%" w="100%">
              <Text data-testid="notification-avatar" size="lg">
                {edge.node.titleUrl ? <Link href={edge.node.titleUrl as Route} style={{
              textDecoration: 'none',
              color: 'black'
            }}>
                    {edge.node.title ?? ''}
                  </Link> : edge.node.title}
              </Text>
              {/* <Text c="dimmed" size="md"> */}
              <Title order={5} tt="uppercase" lh="h4">
                {edge.node.content ?? ''}
              </Title>

              {edge.node.friendship?.requester && edge.node.friendship?.state === FriendshipState.Accepted && edge.node.title?.includes('Connection Invite from') ? null : null}

              {edge.node.friendship?.requester && edge.node.friendship?.state === FriendshipState.Accepted && !edge.node.title?.includes('Connection Invite from') ? <ActionButtons notification={edge.node} /> : null}

              {edge.node.friendship?.requester && edge.node.friendship?.state !== FriendshipState.Rejected && edge.node.friendship?.state !== FriendshipState.Accepted ? <ActionButtons notification={edge.node} /> : null}

              <Title order={6} mt="5px">
                {dayjs(edge.node.createdAt).fromNow()}
              </Title>
            </Flex>
          </Flex>
          <Divider color={edge.node.isRead ? '#f1f1f1' : 'white'} />
          <div key={edge.node.id} />
        </div>)}
    </div>;
}
function NotificationsResultInner({
  page,
  skipRendering
}: {
  page: number;
  read?: boolean;
  skipRendering?: boolean;
}) {
  const {
    actor,
    query
  } = useContext(AccountNotificationsContext);
  const variables = useSelector(actor, s => s.context.pageArgsList[page]);

  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  const [{
    data,
    fetching,
    error
  }] = useQuery({
    query: GetPaginatedNotifications,
    variables: {
      ...variables,
      readState: query // TODO refactor to '...query', need to update schema etc
    },
    pause: !variables
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (data?.currentAccount?.__typename === 'Error') {
      // why?
    } else {
      const pageInfo = data?.currentAccount?.notifications?.pageInfo;
      const totalCount = data?.currentAccount?.notifications?.totalCount;
      // console.log('pageinfo', pageInfo)

      startTransition(() => {
        if (pageInfo) {
          console.log('sending update page info', {
            type: 'UPDATE_PAGE_INFO',
            pageNumber: page,
            pageInfo,
            totalCount
          });
          actor.send({
            type: 'UPDATE_PAGE_INFO',
            pageNumber: page,
            pageInfo,
            totalCount
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (skipRendering) return null;
  if (data?.currentAccount?.__typename === 'Error') {
    return null;
  }
  const notificationsEdges = data?.currentAccount?.notifications?.edges || [];
  const Inner = NotificationsList;
  return <Inner edges={notificationsEdges} data-sentry-element="Inner" data-sentry-component="NotificationsResultInner" data-sentry-source-file="NotificationsList.tsx" />;
}
function ScrollFetcher({
  children
}: React.PropsWithChildren) {
  const {
    actor
  } = useContext(AccountNotificationsContext);
  const actorState = useSelector(actor, s => s);
  function notifyMachine(inView: boolean) {
    console.log('notifyMachine IN_VIEW ', inView);
    if (inView) startTransition(() => actor.send({
      type: 'NEXT_PAGE'
    }));
  }
  const {
    ref
  } = useInView({
    rootMargin: '1000px 0px',
    triggerOnce: true,
    initialInView: false,
    onChange: notifyMachine
  });
  const inViewKey = `notifications-page-${actorState.context.lastLoadedPage}`;
  return <>
      {children}
      <div className={inViewTriggerDiv} key={inViewKey} ref={ref} />
    </>;
}
export function NotificationsListComponent({
  skipRendering = false
}: {
  skipRendering?: boolean;
} = {}) {
  const {
    actor
  } = useContext(AccountNotificationsContext);
  // const pageArgsList = useSelector(actor, (state) => state.context.pageArgsList)
  const pages = useSelector(actor, state => Object.keys(state.context.pageArgsList).map(Number));

  // WARNING
  // the suspense around SearchResultPage is required
  // otherwise the higher level suspense will be triggered
  // and the scroll will jump
  return <ScrollFetcher data-sentry-element="ScrollFetcher" data-sentry-component="NotificationsListComponent" data-sentry-source-file="NotificationsList.tsx">
      <>
        {pages.map(page => <Suspense key={page}>
            <NotificationsResultInner page={page} key={page} skipRendering={skipRendering} />
          </Suspense>)}
      </>
    </ScrollFetcher>;
}