'use client';

import { Flex, Tooltip } from '@mantine/core';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import { AvatarSizes, SafeImage } from '~/app/_components';
import { AppLayoutContext, IdentityContext } from '~/app/_components/context';
import { TrimmedAccountFragment } from '~/generated/graphql';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
export function SocialInfo({
  account
}: {
  account: TrimmedAccountFragment;
}) {
  const {
    mobile
  } = useContext(AppLayoutContext);
  const pathname = usePathname();
  const {
    currentAccount
  } = useContext(IdentityContext);
  const size: AvatarSizes = mobile ? !pathname.includes('/accounts') ? 'sm' : 'sm' : 'xl';

  // MOVE TO UTILS OR SOMETHING
  function normalizeUrl(url: string): string {
    // Remove leading http:// or https://
    if (url.startsWith('http://')) {
      url = url.slice(7);
    } else if (url.startsWith('https://')) {
      url = url.slice(8);
    }

    // Remove leading www.
    if (url.startsWith('www.')) {
      url = url.slice(4);
    }

    // Add https://www. to the start
    return `https://www.${url}`;
  }
  function sanitizeHandle(input: string): string {
    // Remove leading and trailing whitespace
    let handle = input.trim();

    // Check if the input is a URL and extract the handle
    const urlPattern = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9._]+)/;
    const match = handle.match(urlPattern);
    if (match) {
      handle = `@${match[1]}`;
    }

    // Ensure the handle starts with '@'
    if (!handle.startsWith('@')) {
      handle = `@${handle}`;
    }

    // Convert to lowercase
    handle = handle.toLowerCase();

    // Remove any invalid characters
    // Instagram handles can only contain letters, numbers, periods, and underscores
    const validHandle = handle.replace(/[^a-z0-9._]/g, '');
    return validHandle;
  }
  const getInstagramUrl = (handle: string): string => {
    const sanitizedHandle = sanitizeHandle(handle);
    return `https://instagram.com/${sanitizedHandle}`;
  };
  if (!account.socialInfo?.website && !account.socialInfo?.igHandle) {
    return null;
  }
  if (!currentAccount) {
    return null;
  }
  return <Flex gap="md" align="center" m="0px" data-sentry-element="Flex" data-sentry-component="SocialInfo" data-sentry-source-file="SocialInfo.tsx">
      {account.socialInfo?.website ? <a href={normalizeUrl(account.socialInfo?.website)} target="_blank" rel="noreferrer noopener" style={{
      textDecoration: 'none'
    }}>
          <Tooltip label="Website" position="bottom-start">
            <GlobeAltIcon height={30} color="black" />
          </Tooltip>
        </a> : null}
      {account.socialInfo?.igHandle ? <a href={getInstagramUrl(account.socialInfo?.igHandle)} target="_blank" rel="noreferrer noopener" style={{
      textDecoration: 'none'
    }}>
          <SafeImage alt="avatar" height={25} width={25} sizes={`${size}px`} style={{
        objectFit: 'cover'
      }} src={'/icons/Instagram2.webp'}
      // sizes="(max-width: 768px) 100vw, (max-width: 990px) 50vw, (min-width: 1407px) 33vw"
      />
        </a> : null}
    </Flex>;
}