'use client';

import type React from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { AccountsSearchQuery, OrderBy } from '~/generated/graphql';
import { appTrace } from '~/utils';
import { ProfessionsFilterContext } from './ProfessionsFilterContext';
import { RelayPaginationContext } from './RelayPaginationContext';
export type AccountsFilterContextType = {
  location: string;
  freeTextSearch: string;
  locationSearch: string[];
  // orderDirection: OrderDirection
  // order: keyof ProfileOrderBy
  searchQuery: AccountsSearchQuery;
  setLocation: (location: string) => void;
  setFreeTextSearch: (freeText: string) => void;
  setLocationSearch: (arg: string[] | ((a: string[]) => string[])) => void;
  // setOrderDirection: (orderDirection: OrderDirection) => void
  // setOrder: (order: keyof ProfileOrderBy) => void
  resetSearch: () => void;
  setSearchQuery: (query: AccountsSearchQuery) => void;
};
const ctxDefault: AccountsFilterContextType = {
  location: '',
  freeTextSearch: '',
  locationSearch: [],
  // orderDirection: OrderBy.Asc,
  // orderDirection: OrderBy.Asc,
  // order: 'lastName',
  searchQuery: {},
  setLocation: () => {},
  setFreeTextSearch: () => {},
  setLocationSearch: () => {},
  // setOrderDirection: () => {},
  // setOrder: () => {},
  resetSearch: () => {},
  setSearchQuery: () => {}
};
export const AccountsFilterContext = createContext<AccountsFilterContextType>(ctxDefault);
export type OrderDirection = OrderBy.Asc | OrderBy.Desc;
export type OrderKey = OrderBy.Asc | OrderBy.Desc;

// export const AccountsFilterOrderByKeys: Array<keyof ProfileOrderBy> = [
//   'brand',
//   'lastName',
// ]

export function AccountsFilterProvider({
  children
}: React.PropsWithChildren) {
  const {
    actor
  } = useContext(RelayPaginationContext);
  const [location, setLocation] = useState(ctxDefault.location);
  const [freeTextSearch, setFreeTextSearch] = useState(ctxDefault.freeTextSearch);
  const [locationSearch, setLocationSearch] = useState(ctxDefault.locationSearch);

  // const [orderDirection, setOrderDirection] = useState<OrderDirection>(
  //   ctxDefault.orderDirection,
  // )
  const [searchQuery, setSearchQuery] = useState({} as AccountsSearchQuery);
  // const [order, setOrder] = useState<keyof ProfileOrderBy>(ctxDefault.order)

  const {
    professions,
    setProfessions
  } = useContext(ProfessionsFilterContext);
  function assembleQuery() {
    // OR (ID FILTERS)
    // Filters = AND (location profile professions)
    setSearchQuery({
      // orderBy: {
      //   profile: {
      //     [order]: orderDirection,
      //   },
      // },
      where: {
        OR: [{
          id: freeTextSearch ? freeTextSearch : undefined
        }, {
          AND: [{
            profile: locationSearch.length > 0 ? {
              OR: locationSearch.map(location => ({
                location: {
                  contains: location
                }
              }))
            } : undefined
          }, {
            profile: freeTextSearch ? {
              OR: freeTextSearch.split(/[\s,]+/).map(term => ({
                OR: [{
                  name: {
                    contains: term
                  }
                }, {
                  brand: {
                    contains: term
                  }
                }, {
                  about: {
                    contains: term
                  }
                }]
              }))
            } : undefined
          }, {
            professions: professions.length > 0 ? {
              some: {
                OR: professions.map(id => ({
                  id
                }))
              }
            } : undefined
          }]
        }]
      }
    });
  }
  useEffect(() => {
    const query = assembleQuery();
    appTrace('AFP query update', {
      query
    });
    actor.send({
      type: 'RESET'
      // data: { query },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professions, freeTextSearch, locationSearch]);
  const resetSearch = useMemo(() => () => {
    // setOrder('lastName')
    // setOrderDirection(OrderBy.Asc)
    setFreeTextSearch('');
    setProfessions([]);
  }, [setProfessions]);
  const values = useMemo(() => ({
    location,
    setLocation,
    freeTextSearch,
    setFreeTextSearch,
    locationSearch,
    setLocationSearch,
    // order,
    // setOrder,
    // orderDirection,
    // setOrderDirection,
    resetSearch,
    searchQuery,
    setSearchQuery
  }), [location, freeTextSearch, locationSearch,
  // order,
  // orderDirection,
  resetSearch, searchQuery, setSearchQuery]);
  return <AccountsFilterContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="AccountsFilterProvider" data-sentry-source-file="AccountsFilterContext.tsx">{children}</AccountsFilterContext.Provider>;
}