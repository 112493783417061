'use client';

import { graphql } from '~/generated/gql';
export const FullNotificationFragment = graphql(/* GraphQL */`
  fragment FullNotification on Notification {
    __typename
    id
    createdAt
    isRead
    icon
    title
    titleUrl
    requesterEmail
    requesterPhone
    addresseEmail
    addressePhone
    content
    friendship {
      __typename
      id
      state
      requester {
        ...TrimmedAccount
      }
      addressee {
        ...TrimmedAccount
      }
    }
    message {
      __typename
      id
      authorId
      content
      createdAt
      recipientId
    }
  }
`);
export const FullNotificationsConnectionFragment = graphql(/* GraphQL */`
  fragment FullNotificationsConnection on AccountNotificationsConnection {
    __typename
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      __typename
      cursor
      node {
        ...FullNotification
      }
    }
  }

  fragment FullUnreadNotifications on Account {
    notifications(after: null, first: 10, readState: UNREAD) {
      ...FullNotificationsConnection
    }
  }
`);
export const GetPaginatedNotifications = graphql(/* GraphQL */`
  query GetPaginatedNotifications(
    $first: Int!
    $after: String
    $readState: NotificationReadState
  ) {
    currentAccount {
      __typename
      ... on Account {
        __typename
        id
        notifications(first: $first, after: $after, readState: $readState) {
          ...FullNotificationsConnection
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

// export type PaginatedNotificationsData = Partial<
//   Pick<Notification, 'id' | 'readState'>
// >