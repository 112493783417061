'use client';

import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { ArrowRightOnRectangleIcon, BriefcaseIcon,
// CogIcon,
GlobeAltIcon, InformationCircleIcon, MagnifyingGlassCircleIcon, PencilIcon, UserGroupIcon, UserIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { Flex, NavLink, Text } from '@mantine/core';
import type { Route } from 'next';
import { useRouter } from 'next/navigation';
import { useContext, useMemo } from 'react';
import { itemDescription, itemLabel, labelLeftSection } from '.';
import { AppLayoutContext, IdentityContext, LocalConfigContext, useAppAuth } from '~/app/_components';
import { TrimmedAccountFragment } from '~/generated/graphql';
import { accountRoute } from '~/utils';

//type BurgerItemKey = keyof typeof burgerItems

type BurgerLink = {
  label: string;
  desc: string;
  url: Route;
  icon: typeof UserIcon;
  nextVersion?: boolean;
};
const burgerItemKey = ['signin', 'signout', 'community', 'connect', 'projects', 'invite',
// 'settings',
'review', 'contacts', 'terms', 'profile'];
type BurgerItemKeyType = (typeof burgerItemKey)[number];
function makeBurgerItems(account?: TrimmedAccountFragment): Record<BurgerItemKeyType, BurgerLink> {
  const items: Record<BurgerItemKeyType, BurgerLink> = {
    signin: {
      label: 'Sign in',
      desc: 'Log in or create your account',
      url: '/',
      icon: UserIcon
    },
    signout: {
      label: 'Sign out',
      desc: '',
      url: '/',
      icon: ArrowRightOnRectangleIcon
    },
    community: {
      label: 'Community',
      desc: 'Browse the community',
      url: '/',
      icon: GlobeAltIcon
    },
    connect: {
      label: 'Connect',
      desc: 'Build your creative network',
      url: '/',
      icon: MagnifyingGlassCircleIcon
    },
    projects: {
      label: 'Projects',
      desc: 'Search for projects',
      url: '/projects',
      icon: BriefcaseIcon
    },
    invite: {
      label: 'Invite',
      desc: 'Invite people to the app',
      url: '/invite',
      icon: UserPlusIcon
    },
    // settings: {
    //   label: 'Settings',
    //   desc: 'Manage your account',
    //   url: '/settings',
    //   icon: CogIcon,
    // },
    review: {
      label: 'Review',
      desc: 'Review Dome',
      url: '/review',
      icon: PencilIcon
    },
    contacts: {
      label: 'Contacts',
      desc: 'View your Contacts',
      url: '/contacts',
      icon: UserGroupIcon
    },
    terms: {
      label: 'Terms',
      desc: 'Terms and Conditions',
      url: '/terms',
      icon: InformationCircleIcon
    }
    // messages: {
  };
  if (account) items.profile = {
    label: 'Profile',
    desc: 'Manage your profile',
    url: accountRoute(account.id).show,
    icon: UserIcon
  };
  return items;
}
export function BurgerMenu({
  close
}: {
  close: () => void;
}) {
  const router = useRouter();
  const {
    flags
  } = useContext(LocalConfigContext);
  const {
    mobile
  } = useContext(AppLayoutContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const burgerItems = useMemo(() => makeBurgerItems(currentAccount), [currentAccount]);
  const {
    handleSignOut,
    handleSignIn
  } = useAppAuth();
  function navigate(url: Route) {
    router.push(url);
    close();
  }
  function calcBurgerMenuItems(): BurgerItemKeyType[] {
    return mobile ? ['community', 'projects', 'contacts', 'profile',
    // 'settings',
    // 'invite',
    'review', 'signout', 'terms'] : ['review', 'terms'];
  }

  // TODO this list must depend on the registration/signin status
  const itemNames: BurgerItemKeyType[] = currentAccount ? calcBurgerMenuItems() : ['signin', 'community', 'terms'];
  const iconSize = 30;
  const items = itemNames.map(n => burgerItems[n]).filter(item => flags.showNextVersion ? true : !('nextVersion' in item));
  function handleAuthAction(action: string) {
    if (action === 'Sign out' || action === 'Sign in') {
      action === 'Sign out' ? handleSignOut() : handleSignIn();
    }
  }
  return <>
      {items.map(s => <NavLink key={s.label} label={s.label} description={s.desc} leftSection={<s.icon width={iconSize} />} rightSection={<ChevronRightIcon height={iconSize} width={iconSize} />} onClick={() => {
      s.label === 'Sign out' || s.label === 'Sign in' ? handleAuthAction(s.label) : navigate(s.url);
    }} classNames={{
      label: itemLabel,
      description: itemDescription,
      section: labelLeftSection
    }} />)}
      <Flex px="md" mt={mobile ? 'xl' : '100px'} mb="sm" gap={mobile ? 'sm' : 'md'} direction="column" data-sentry-element="Flex" data-sentry-source-file="BurgerMenu.tsx">
        <a href="https://www.createwithdome.com/how-dome-works" target="_blank" rel="noopener noreferrer" style={{
        textDecoration: 'none',
        color: 'inherit'
      }}>
          <Text fw={500} size="xl" data-sentry-element="Text" data-sentry-source-file="BurgerMenu.tsx">
            How Dome Works
          </Text>
        </a>

        <a href="https://www.createwithdome.com/become-a-beta-tester" target="_blank" rel="noopener noreferrer" style={{
        textDecoration: 'none',
        color: 'inherit'
      }}>
          <Text fw={500} size="xl" data-sentry-element="Text" data-sentry-source-file="BurgerMenu.tsx">
            Become a beta tester
          </Text>
        </a>

        <a href="https://www.createwithdome.com/contact" target="_blank" rel="noopener noreferrer" style={{
        textDecoration: 'none',
        color: 'inherit'
      }}>
          <Text fw={500} size="xl" data-sentry-element="Text" data-sentry-source-file="BurgerMenu.tsx">
            Contact
          </Text>
        </a>
      </Flex>
    </>;
}