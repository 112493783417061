'use client';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { Badge, Container, Divider, Flex, TextInput, Title } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { StyledButton } from '~/app/_components/buttons';
import { ProfessionsFilter } from '~/app/_components/search/desktop/ProfessionsFilter';
import { inputWrapperBorder } from '~/app/projects/styles.css';
import { AccountsFilterContext } from '../AccountsFilterContext';
import { mobileFilterAction, mobileFilterWrapper } from './styles.css';
import { CommonAutocompleteInput } from '~/app/(me)/_components';
type SearchFilterProps = {
  closeFilters: () => void;
};
export function SearchFilterMobile({
  closeFilters
}: SearchFilterProps) {
  const [height, setHeight] = useState(window.innerHeight);
  const {
    freeTextSearch,
    setFreeTextSearch,
    resetSearch,
    setLocationSearch,
    locationSearch
  } = useContext(AccountsFilterContext);
  const [textSearchInput, setTextSearchInput] = useState<string>(freeTextSearch);
  const [locationSearchBadges, setLocationSearchBadges] = useState<string[]>([]);
  function handleTextInputChange(event: any) {
    setTextSearchInput(event.target.value);
  }
  function handleFilter() {
    setFreeTextSearch(textSearchInput);
    closeFilters();
  }
  function handleReset() {
    setTextSearchInput('');
    resetSearch();
    closeFilters();
    setLocationSearch([]);
  }
  useEffect(() => {
    if (!locationSearch.length) return;
    setLocationSearchBadges(locationSearch);
  }, [locationSearch]);
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  function handleLocationRemoval(location: string) {
    setLocationSearch(prevLocationSearch => {
      return prevLocationSearch.filter((loc: string) => loc !== location);
    });
    setLocationSearchBadges(prevLocationSearchBadges => {
      return prevLocationSearchBadges.filter((loc: string) => loc !== location);
    });
  }
  return <>
      <Flex direction="column" justify="space-between" className={mobileFilterWrapper} data-testid="filter-modal" style={{
      height: height
    }} data-sentry-element="Flex" data-sentry-source-file="SearchFilterMobile.tsx">
        <Flex direction="column" style={{
        overflowY: 'scroll'
      }} mt="50px" mx="lg" data-sentry-element="Flex" data-sentry-source-file="SearchFilterMobile.tsx">
          <Flex w="100%" justify="center" direction="column" data-sentry-element="Flex" data-sentry-source-file="SearchFilterMobile.tsx">
            <Title order={3} ta="left" w="100%" mb="6px" data-sentry-element="Title" data-sentry-source-file="SearchFilterMobile.tsx">
              BY LOCATION
            </Title>
            <CommonAutocompleteInput autoclear={true} classNames={{
            input: inputWrapperBorder
          }} onPlaceSelected={place => {
            setLocationSearch(prevLocationSearch => {
              const newLocation = place?.formatted_address ?? '';
              return [...prevLocationSearch, newLocation];
            });
            setLocationSearchBadges(prevState => [...prevState, place?.formatted_address]);
          }} data-sentry-element="CommonAutocompleteInput" data-sentry-source-file="SearchFilterMobile.tsx" />
          </Flex>
          {locationSearchBadges.length ? <Flex direction="row" gap="sm" px="md" pt="xs" pb={0} wrap="wrap">
              {locationSearchBadges.map((loc, i) => <Badge h={25} c="black" key={i} leftSection={<XMarkIcon height={15} onClick={_e => handleLocationRemoval(loc)} style={{
            cursor: 'pointer'
          }} />}>
                  {locationSearchBadges[i]}
                </Badge>)}
            </Flex> : null}

          <Container py="xl" px="0px" data-sentry-element="Container" data-sentry-source-file="SearchFilterMobile.tsx">
            <Title order={3} ta="left" w="100%" mb="6px" data-sentry-element="Title" data-sentry-source-file="SearchFilterMobile.tsx">
              BY PROFESSION
            </Title>
            <ProfessionsFilter data-sentry-element="ProfessionsFilter" data-sentry-source-file="SearchFilterMobile.tsx" />
          </Container>

          <Flex direction="column" data-sentry-element="Flex" data-sentry-source-file="SearchFilterMobile.tsx">
            <Title order={3} ta="left" w="100%" mb="6px" data-sentry-element="Title" data-sentry-source-file="SearchFilterMobile.tsx">
              BY KEYWORD
            </Title>
            <TextInput size="md" p="0px" placeholder="Search for name, brand or location" value={textSearchInput ?? freeTextSearch} onChange={handleTextInputChange} data-testid="mobile-search-input" classNames={{
            input: inputWrapperBorder
          }} data-sentry-element="TextInput" data-sentry-source-file="SearchFilterMobile.tsx" />
          </Flex>
          {/* <SearchFilterMobileSort /> */}
        </Flex>
        <Flex direction="column" w="100%" style={{
        position: 'fixed',
        bottom: '0px'
      }} data-sentry-element="Flex" data-sentry-source-file="SearchFilterMobile.tsx">
          <Divider data-sentry-element="Divider" data-sentry-source-file="SearchFilterMobile.tsx" />
          <Flex className={mobileFilterAction} w="100%" p="md" mb="md" justify="space-between" gap="sm"
        // style={{ position: 'fixed', bottom: '0px' }}
        data-sentry-element="Flex" data-sentry-source-file="SearchFilterMobile.tsx">
            <StyledButton type="passive" text="RESET" fz="15px" size="xLarge" disablePadding={true} onClick={handleReset} data-sentry-element="StyledButton" data-sentry-source-file="SearchFilterMobile.tsx" />
            <StyledButton type="primary" text="FILTER" fz="15px" size="xLarge" onClick={handleFilter} testId="mobile-filter-button" data-sentry-element="StyledButton" data-sentry-source-file="SearchFilterMobile.tsx" />
          </Flex>
        </Flex>
      </Flex>
    </>;
}