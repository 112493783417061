'use client';

import { Box, Center, Flex, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@urql/next';
import { usePathname } from 'next/navigation';
import type React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AppLayoutContext, IdentityContext, PeerAccountContext, StyledButton, useAppAuth } from '~/app/_components';
import { AcceptOrCreateFriendship, DeclineFriendship } from '~/app/_graphql';
import { type Account, AccountType, ConnectionState } from '~/generated/graphql';
import { appTrace } from '~/utils';
type AccountWithConnectStatus = Pick<Account, 'id' | 'connectionStatus' | 'type' | 'profile'>;
type ButtonVariants = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';
const ConnectButtonTexts = {
  connect: 'CONNECT',
  connected: 'CONNECTED',
  sent: 'INVITE SENT',
  received: 'INVITE RECEIVED'
};
function renderBtnText(account: AccountWithConnectStatus, business = false) {
  if (account.connectionStatus === ConnectionState.Connected) return ConnectButtonTexts.connected;
  if (account.connectionStatus === ConnectionState.Sent) return ConnectButtonTexts.sent;
  // if (account.type === AccountType.Personal && business) return `${ConnectButtonTexts.connect} WITH ${account.profile?.name?.toUpperCase()}`
  if (account.type !== AccountType.Personal && business) return `${ConnectButtonTexts.connect} WITH ${account.profile?.name?.toUpperCase()}`;
  return ConnectButtonTexts.connect;
}
export function ConnectButton({
  variant,
  defaultHide = false,
  disablePadding = false,
  justify = 'center',
  noPadding = false,
  width,
  slimHeight,
  business,
  fz,
  flexDirection = 'column'
}: {
  variant?: ButtonVariants;
  defaultHide?: boolean;
  disablePadding?: boolean;
  justify?: string;
  noPadding?: boolean;
  width?: string;
  slimHeight?: boolean;
  business?: boolean;
  fz?: string;
  flexDirection?: string;
}) {
  const {
    account,
    navigateToProfile
  } = useContext(PeerAccountContext);
  const {
    isSignedIn,
    handleSignIn
  } = useAppAuth();
  const {
    currentAccount
  } = useContext(IdentityContext);
  const [node, setNode] = useState<React.ReactNode>(<DefaultNode />);
  const [, executeUpdateAccept] = useMutation(AcceptOrCreateFriendship);
  const [, executeUpdateDecline] = useMutation(DeclineFriendship);
  function handleSignInWithReturn() {
    localStorage.setItem('redirect_to', window.location.pathname);
    handleSignIn();
  }
  useEffect(() => {
    setNode(<DynamicNode />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, account.connectionStatus, account, isSignedIn]);
  useEffect(() => {
    setNode(<DynamicNode />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function handleAcceptFriendshipRequest() {
    const result = await executeUpdateAccept({
      recipientId: account.id
    });
    if (result.data?.acceptOrCreateFriendship?.__typename === 'Account') {
      notifications.show({
        title: 'Invite Accepted',
        message: 'The user is now added to your contact list.'
      });
    } else if (result?.data?.acceptOrCreateFriendship?.__typename === 'Error') {
      appTrace('Could not accept the friendship request', {
        result
      });
      notifications.show({
        title: 'Something went wrong',
        message: result.data.acceptOrCreateFriendship.message,
        color: 'warning'
      });
    }
  }
  async function handleDeclineFriendshipRequest() {
    const result = await executeUpdateDecline({
      requesterId: account.id
    });
    if (result.data?.declineFriendship?.__typename === 'FriendshipWithAccount') {
      notifications.show({
        title: 'Invite Declined',
        message: 'The user can still appear in your result feed.'
      });
    } else if (result?.data?.declineFriendship?.__typename === 'Error') {
      appTrace('Could not send the decline', {
        result
      });
      notifications.show({
        title: 'Something went wrong',
        message: result.data.declineFriendship.message,
        color: 'red'
      });
    }
  }
  function openModal() {
    modals.openContextModal({
      modal: 'connect',
      title: `Send ${account.profile?.name} an invite to connect.`,
      innerProps: {
        account
      },
      styles: {
        title: {
          fontSize: '16px'
        }
      }
    });
  }
  function DefaultNode({
    text = ConnectButtonTexts.connect,
    testId = 'navigate-button',
    onClick = navigateToProfile,
    isDisabled = false,
    disablePadding = false,
    noPadding = false,
    fz,
    // biome-ignore lint/correctness/noUnusedVariables: <explanation>
    width
  }: {
    text?: string;
    testId?: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    onClick?: (e: any) => void;
    isDisabled?: boolean;
    disablePadding?: boolean;
    noPadding?: boolean;
    width?: string;
    fz?: string;
  }) {
    const pn = usePathname();
    const {
      mobile
    } = useContext(AppLayoutContext);
    if (account.connectionStatus === ConnectionState.Connected && (pn.includes('/accounts') || pn.includes('/contacts'))) return null;
    if (defaultHide) return null;
    if (!isSignedIn) return <StyledButton testId={testId}
    // size={variant}
    fz={fz ? fz : undefined} type="primary" text={text}
    // shape="square"
    onClick={handleSignInWithReturn} disablePadding={mobile ? true : disablePadding} noPadding={noPadding} width={pn.includes('/accounts') && !mobile ? '100%' : mobile ? pn.includes('/accounts') ? undefined : '82.2px' : '82.2px'} slimHeight={slimHeight ? slimHeight : !pn.includes('/accounts')} isDisabled={isDisabled}
    // isDisabled={false}
    />;
    return currentAccount?.isHidden && isSignedIn && account.connectionStatus !== ConnectionState.Sent ? <Tooltip label="You need to upload at least 1 folio image to interact with other users." data-sentry-element="Tooltip" data-sentry-component="DefaultNode" data-sentry-source-file="ConnectButton.tsx">
        <Flex style={{
        width: '100%',
        justifyContent: 'flex-end'
      }} data-sentry-element="Flex" data-sentry-source-file="ConnectButton.tsx">
          <StyledButton testId={testId}
        // size={variant}
        fz={fz ? fz : undefined} type="primary" text={text}
        // shape="square"
        onClick={onClick} disablePadding={mobile ? true : disablePadding} noPadding={noPadding} width={pn.includes('/accounts') && !mobile ? '100%' : mobile ? pn.includes('/accounts') ? undefined : '82.2px' : '82.2px'} isDisabled={isDisabled || !currentAccount?.isHidden} slimHeight={slimHeight ? slimHeight : !pn.includes('/accounts')}
        // isDisabled={false}
        data-sentry-element="StyledButton" data-sentry-source-file="ConnectButton.tsx" />
        </Flex>
      </Tooltip> : <StyledButton testId={testId}
    // size={variant}
    fz={fz ? fz : undefined} type="primary" text={text}
    // shape="square"
    onClick={onClick} disablePadding={mobile ? true : disablePadding} noPadding={noPadding} width={pn.includes('/accounts') && !mobile ? '100%' : mobile ? pn.includes('/accounts') ? undefined : '82.2px' : '82.2px'} isDisabled={isDisabled} slimHeight={slimHeight ? slimHeight : !pn.includes('/accounts')}
    // isDisabled={false}
    data-sentry-element="StyledButton" data-sentry-component="DefaultNode" data-sentry-source-file="ConnectButton.tsx" />;
  }
  function ActOnInviteButtonInner() {
    const pn = usePathname();
    const {
      mobile
    } = useContext(AppLayoutContext);
    return <Flex direction={flexDirection === 'column' ? 'column' : 'row'} gap="6px" w="100%" justify="flex-start" mt="6px" mb="3px" data-sentry-element="Flex" data-sentry-component="ActOnInviteButtonInner" data-sentry-source-file="ConnectButton.tsx">
        <StyledButton
      // size={variant}
      type="primary" text="ACCEPT" fz={fz ? fz : undefined} onClick={handleAcceptFriendshipRequest} testId="feed-accept-button" disablePadding={disablePadding || mobile} slimHeight={slimHeight ? slimHeight : !pn.includes('/accounts')} data-sentry-element="StyledButton" data-sentry-source-file="ConnectButton.tsx" />
        <StyledButton
      // size={variant}
      fz={fz ? fz : undefined} type="black" text="IGNORE" onClick={handleDeclineFriendshipRequest} testId="feed-decline-button" disablePadding={disablePadding || mobile} slimHeight={slimHeight ? slimHeight : !pn.includes('/accounts')} data-sentry-element="StyledButton" data-sentry-source-file="ConnectButton.tsx" />
      </Flex>;
  }
  function DynamicNode() {
    const isDisabled = account.connectionStatus === ConnectionState.Sent || account.connectionStatus === ConnectionState.Connected;
    const text = renderBtnText(account, business);
    const notConnected = account.connectionStatus === ConnectionState.NotConnected;
    if (!isSignedIn) return <DefaultNode text={text} />;
    return account.connectionStatus === ConnectionState.Received ? <ActOnInviteButtonInner data-sentry-element="ActOnInviteButtonInner" data-sentry-component="DynamicNode" data-sentry-source-file="ConnectButton.tsx" /> : defaultHide ? null : <DefaultNode text={text} noPadding={noPadding} isDisabled={isDisabled || (currentAccount?.isHidden ?? false)}
    // testId="inviteButton"
    onClick={notConnected ? openModal : undefined} disablePadding={disablePadding} width={width} fz={fz ? fz : undefined} />;
  }
  return defaultHide ? null : <>
      <Center w="100%" data-sentry-element="Center" data-sentry-source-file="ConnectButton.tsx">
        <Box w="100%" data-sentry-element="Box" data-sentry-source-file="ConnectButton.tsx">
          <Flex justify={justify} gap="sm" w="100%" pb="0px" data-sentry-element="Flex" data-sentry-source-file="ConnectButton.tsx">
            {node}
          </Flex>
        </Box>
      </Center>
    </>;
}