'use client';

import { Badge, Box, Card, Divider, Flex, Text, Title, UnstyledButton } from '@mantine/core';
import { useRouter } from 'next/navigation';
import { useContext } from 'react';
import { ReportUserMenu } from '../common';
import { ConnectButton } from '../common/ConnectButton';
import { desktopProfileCard, desktopProfileCardHeader, desktopProfileCardTitle, proBadgeLabel, profileCardCarouselWrapper } from './styles.css';
import { IdentityContext, ImagesCarousel, PeerAccountContext, SafeAvatarImage, StyledButton, useAppAuth } from '~/app/_components';
import { accountBrand, accountProfessions, accountRoute, accountType } from '~/utils';
import { AccountTier, AccountType } from '~/generated/graphql';
export function ProBadge() {
  return <Badge color="black" py="0px" px="3px" fz="8px" h="14px" classNames={{
    label: proBadgeLabel
  }} data-sentry-element="Badge" data-sentry-component="ProBadge" data-sentry-source-file="DesktopProfileCard.tsx">
      PRO
    </Badge>;
}
export function DesktopProfileCard() {
  const {
    account,
    navigateToProfile
  } = useContext(PeerAccountContext);
  const {
    currentAccount
  } = useContext(IdentityContext);
  const isCurrentUserProfile = currentAccount?.id === account?.id;
  const {
    isSignedIn
  } = useAppAuth();
  const router = useRouter();
  const routeInfo = {
    show: accountRoute(account.id).show
  };
  return <>
      <Card className={desktopProfileCard} p="0" pt="sm" data-testid="profile-card-desktop" data-sentry-element="Card" data-sentry-source-file="DesktopProfileCard.tsx">
        <Box className={profileCardCarouselWrapper} onClick={navigateToProfile} data-sentry-element="Box" data-sentry-source-file="DesktopProfileCard.tsx">
          <ImagesCarousel data-sentry-element="ImagesCarousel" data-sentry-source-file="DesktopProfileCard.tsx" />
        </Box>

        <Divider my="sm" mx="sm" size="0.5px" data-sentry-element="Divider" data-sentry-source-file="DesktopProfileCard.tsx" />

        <Card.Section mx="xs" data-testid="profile-card" w="100%" pb="xs" h="150px" data-sentry-element="unknown" data-sentry-source-file="DesktopProfileCard.tsx">
          <Flex align="flex-start" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
            <UnstyledButton onClick={navigateToProfile} className={desktopProfileCardHeader} data-testid="profile-card-header" w="100%" data-sentry-element="UnstyledButton" data-sentry-source-file="DesktopProfileCard.tsx">
              <Flex gap="sm" justify="flex-start" w="100%" mt="sm" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                <Flex direction="column" gap="4px" align="center" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                  <SafeAvatarImage radius="xs" account={account} data-sentry-element="SafeAvatarImage" data-sentry-source-file="DesktopProfileCard.tsx" />
                  {account.tier === AccountTier.Pro ? <ProBadge /> : null}
                </Flex>
                <div className={desktopProfileCardTitle}>
                  <Title tt="uppercase" order={4} data-sentry-element="Title" data-sentry-source-file="DesktopProfileCard.tsx">
                    {account.profile?.name}
                  </Title>
                  <Flex direction="column" gap="0px" pt="6px" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                    {account.type !== AccountType.Personal ? <Text size="md" c={'black'} lineClamp={1} pr="md" fw={600} tt="uppercase">
                        {accountType(account)}
                      </Text> : null}
                    {account.profile?.brand ? <Text size="md" c={'dimmed'} lineClamp={2} pr="md">
                        {accountBrand(account)}
                      </Text> : null}
                    {account.type === AccountType.Personal && account.professions && account.professions.length > 0 ? <Text size="md" c={'dimmed'} lineClamp={2} pr="md">
                        {accountProfessions(account)}
                      </Text> : null}
                    {account.profile?.location ? <Text size="md" c={'dimmed'} lineClamp={2} pr="md" tt="capitalize">
                        {account.profile?.location}
                      </Text> : null}
                  </Flex>
                </div>
              </Flex>
            </UnstyledButton>
            <Flex direction="column" mr="xs" pr="xs" gap="5px" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
              {isSignedIn && !isCurrentUserProfile ? <ConnectButton variant="xSmall" disablePadding={true} noPadding={true} fz="10px" /> : null}
              <StyledButton text="VIEW FOLIO" width="82.2px" noPadding={true} type="black" fz="10px" disablePadding={true} onClick={() => router.push(routeInfo.show)} testId="view-folio-button" slimHeight={true} data-sentry-element="StyledButton" data-sentry-source-file="DesktopProfileCard.tsx" />

              <Flex justify="flex-end" mb="xs" data-sentry-element="Flex" data-sentry-source-file="DesktopProfileCard.tsx">
                <ReportUserMenu data-sentry-element="ReportUserMenu" data-sentry-source-file="DesktopProfileCard.tsx" />
              </Flex>
            </Flex>
          </Flex>
        </Card.Section>
      </Card>
    </>;
}